.grouping-edit-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .grouping-edit-buttons {
    top: 10px;
    right: 10px;
  }
}
