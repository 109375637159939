.ol-map {
    height: 70dvh;
    width: 100%;
  }

  .pd5{
    padding:5px;
  }
  .map-container-flex{
    display: flex;
    flex-direction: row;
  }
  .map-hidden{
    display: none;
  }
  .ol-map-half{
    min-height: 400px;
    height: 450px;
    width: 50%;
  }
  .full-width{
    width:100%;
  }
  .half-width{
    width:50%;
  }
  .ol-control {
    position: absolute;
    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 2px;
  }
  .ol-full-screen {
    top: .5em;
    right: .5em;
  }


  .ol-full-screen {
    top: 0.5em;
    right: 0.5em;
  }
  
  .ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 100px;
  }
  .ol-popup:after,
  .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "✖";
  }